module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"titleTemplate":"Zenith Roofing Services | %s","description":"Servicing residential and commercial roofing for San Diego, Temecula, Orange, Riverside and Los Angeles Counties","language":"en","openGraph":{"type":"website","locale":"en_US","url":"https://zenithroofingservices.com","site_name":"Zenith Roofing Services","images":[{"url":"zenithroofingservices.com/logo/logo.png","alt":"Zenith Roofing Services Logo"}]},"twitter":{"handle":"@zenithroofingservices","site":"@site","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-164476957-1","head":true,"anonymize":true,"respectDNT":true,"cookieDomain":"zenithroofingservices.com","exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"6","matomoUrl":"https://analytics.viadelweb.cloud","siteUrl":"https://zenithroofingservices.com","matomoPhpScript":"piwik.php","matomoJsScript":"piwik.js","exclude":["/offline-plugin-app-shell-fallback/"],"requireConsent":false,"requireCookieConsent":false,"disableCookies":false,"cookieDomain":"*.zenithroofingservices.com","dev":false,"enableJSErrorTracking":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zenith Roofing Services","short_name":"Zenith Roofing","description":"Roofing Services for San Diego","lang":"en","start_url":"/","background_color":"#0d4879","theme_color":"#ff9900","display":"standalone","icon":"src/images/logo-vert-square-x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"164a0d9623b54624d3f65b2c794e0c7d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
